import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Icon, Popover, Radio, Row, Select } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { Link, Route } from 'react-router-dom';
import { goBack, push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IRootState } from '../../../../shared/reducers';
import { IconArrowsAltHSolid, IconClose, IconDollySolid, IconMedkit, IconUserPlus } from '../../../../components/icons';
import { EntityRemoteSelect } from '../../../../components/select/EntityRemoteSelect';
import { ProjectCreateModal } from '../../projects/production/modal/project-create-modal';
import { startNewOperation } from '../reducers/operationForm.reducer';
import {
    CounterpartyActivityTypeCodeEnum,
    OperationTypeCodeEnum,
    ProjectInfoRead,
    ProjectRecord,
    ProjectStateCodeEnum,
    ProjectTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RenterInfoRead,
    RenterStateCodeEnum,
    RentStateCodeEnum,
    SubrentInfoRead,
    SubrentRecord,
    SubrentStateCodeEnum,
    TaxBaseTypeCodeEnum,
} from '../../../../server/api';
import { serverApi } from '../../../../server';
import { showNotification } from '../../../../components/notification/showNotification';
import { logEvent } from '../../../../analytics/analytics';
import { LocalizationEnum, localize, localizeIntl } from '../../../../localization';
import { RRoundButton } from '../../../../components/button/rRoundButton';
import { getShiftCountFromDates } from '../../../../shared/util/utils';
import './NewOperationModule.less';
import Spin from '../../../../components/spin/spin';
import { RecalculateShiftsCountButton } from '../RecalculateShiftsCountButton';
import { SubrentShippingCreateModal } from '../../subrent/shippings/subrentShippingCreateModal';
import { getStoreState } from '../../../../../index';
import { AdditionalEntityActionsButton } from '../../../../components/additionalEntityActions/additionalEntityActionsButton';
import {
    getOperationTypeDataByCodes,
    getRentStateDataByCodes,
    IOperationTypeData,
    IRentStateData,
    RENT_STATE_CODES,
    SUBRENT_STATE_CODES,
} from '../../../../types';
import { CorrectionTargetStateSelectPopover } from '../components/CorrectionTargetStateSelectPopover';
import { getCurrentServerTime } from '../../../../shared/reducers/system.reducer';
import { createLabeledValue } from '../../../../shared/util/utils5';
import { showActualizeDatesConfirm } from '../../../../shared/util/showActualizeDatesConfirm';
import { canCreateCorrectionOperation, canViewFinancialData } from '../../../../shared/util/permissionUtils';
import { listConcurrentOperationsAndShowConfirm } from '../listConcurrentOperationsAndShowConfirm';
import { createProjectModalFilters } from '../../projects/production/modal/projectsPageData';
import { createSubrentShippingSchemeModalFilters } from '../../subrent/shippings/subrentShippingCreateModalData';
import { RadioChangeEvent } from 'antd/lib/radio';
import { NumberInput, NumberInputDiscount, NumberInputShiftCount } from '../../../../components/numberInputs';
import { CounterpartyCreateModalNew } from '../../renters/tabs/counterparties/modal/CounterpartyCreateModalNew';
import { offersModuleEnabledSelector, subrentModuleEnabledSelector } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { ProjectStateBadge } from '../../projects/production/components/ProjectStateBadge/ProjectStateBadge';
import { SubrentShippingStateBadge } from '../../subrent/shippings/components/SubrentShippingBadge/SubrentShippingBadge';
import { Dot } from '../../../../components/dot/dot';
import { RangePicker } from '../../../../components/v2/calendar/rangePicker/rangePicker';

interface IState {
    rentType: RentTypeEnum;
    operationTypeCode?: OperationTypeCodeEnum;
    targetTypeCode?: RentStateCodeEnum;
    isLoading: boolean;
    defaultStartDate: Moment;

    renter?: LabeledValue;
    renterObj?: RenterInfoRead;
    project?: LabeledValue;
    projectObj?: ProjectInfoRead | SubrentInfoRead;

    startDate?: Moment | null;
    endDate?: Moment | null;
    shiftCount?: number;
    discount?: number;

    subrentHoursBeforeRent?: number;
    subrentHoursAfterReturn?: number;
}

enum RentTypeEnum {
    RENT = 'rent',
    SUBRENT = 'subrent',
}

interface IProps extends StateProps, DispatchProps, WrappedComponentProps {}

class Component extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        const data = this.getStorageData();

        this.state = {
            isLoading: false,
            renter: this.props.renterId ? createLabeledValue(this.props.renterId, this.props.renterShortName) : undefined,
            project: this.props.projectId ? createLabeledValue(this.props.projectId, this.props.projectShortName) : undefined,
            rentType: this.props.isSubrent || this.props.subrentForShortage ? RentTypeEnum.SUBRENT : RentTypeEnum.RENT,
            defaultStartDate: moment(getCurrentServerTime()) /*.seconds(0)*/
                .milliseconds(0),
            subrentHoursBeforeRent: data.subrentHoursBeforeRent || 0,
            subrentHoursAfterReturn: data.subrentHoursAfterReturn || 0,
        };
    }

    componentDidMount(): void {
        if (this.state.renter && this.state.project) {
            this.setState({ isLoading: true });
            this.loadRenter(+this.state.renter.key, +this.state.project.key);
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.isSubrent !== this.props.isSubrent) {
            this.setState({
                rentType: this.props.isSubrent ? RentTypeEnum.SUBRENT : RentTypeEnum.RENT,
                renter: undefined,
                project: undefined,
            });
        } else if (prevProps.renterId !== this.props.renterId && !this.props.renterId) {
            this.setState({
                rentType: this.props.isSubrent ? RentTypeEnum.SUBRENT : RentTypeEnum.RENT,
                renter: undefined,
                project: undefined,
            });
        }
    }

    loadRenter = async (renterId: number, projectId: number) => {
        renterId = +renterId;
        projectId = +projectId;

        try {
            const renterResponse: AxiosResponse<RenterInfoRead> = await serverApi.getRenterById(this.props.businessAccountId, renterId);

            let projectResponse: AxiosResponse<ProjectInfoRead | SubrentInfoRead> | undefined;

            if (this.state.rentType === RentTypeEnum.RENT) {
                projectResponse = await serverApi.getProjectById(this.props.businessAccountId, projectId);
            } else if (this.state.rentType === RentTypeEnum.SUBRENT) {
                projectResponse = await serverApi.getSubrentById(this.props.businessAccountId, projectId);
            }

            this.setState({
                isLoading: false,
                renterObj: renterResponse.data,
                projectObj: projectResponse?.data,

                ...this.getProjectParams(projectResponse?.data),
            });
        } catch (e) {
            this.setState({ isLoading: false });
        }
    };

    onRenterSelectChange = (value: any) => {
        console.log('onRenterSelectChange', value);

        this.setState({
            renter: value,
            renterObj: value?.data ? value.data : undefined,
            project: undefined,
            projectObj: undefined,
            isLoading: false,
            //
            startDate: undefined,
            endDate: undefined,
            shiftCount: undefined,
            discount: undefined,
        });
    };

    getProjectParams = (projectObj: ProjectInfoRead | SubrentInfoRead | undefined /*, renterObj:RenterInfoRead|undefined*/) => {
        let startD;
        let endD;
        let shiftCount;
        let discount: number | undefined;

        if (projectObj) {
            if ('defaultDiscount' in projectObj) {
                discount = projectObj.defaultDiscount;
            }
            if (!discount) discount = 0;
        }

        if (projectObj) {
            if (projectObj.startDate && projectObj.targetFinishDate) {
                startD = moment(projectObj.startDate).seconds(0).milliseconds(0);
                endD = moment(projectObj.targetFinishDate).seconds(0).milliseconds(0);
            } else {
                startD = moment(this.state.defaultStartDate);
                endD = moment(this.state.defaultStartDate).add(7, 'd');
            }
            //shiftCount = getShiftCountFromDates(startD, endD, this.props.defaultShiftLengthInMinutes, this.props.shiftCountRoundingType);
        }

        return {
            startDate: startD,
            endDate: endD,
            shiftCount: shiftCount,
            discount: discount,
        };
    };

    onProjectSelectChange = async (value: any) => {
        console.log('onProjectSelectChange', value);

        const project = value;
        const projectObj: ProjectInfoRead | SubrentInfoRead | undefined = value?.data ? value.data : undefined;

        this.setState({
            project: project,
            projectObj: projectObj,
            isLoading: false,
            ...this.getProjectParams(projectObj),
        });
    };

    // Создали Контрагента из модального окна
    renterCreatedCallback = (data: RenterInfoRead) => {
        const { businessAccountId, push } = this.props;
        push(`/${businessAccountId}/operation`);
        this.setState({
            renter: createLabeledValue(data.id, data.shortName),
            renterObj: data,
            project: undefined,
            projectObj: undefined,

            startDate: undefined,
            endDate: undefined,
            shiftCount: undefined,
            discount: undefined,
        });
        logEvent({
            type: 'create renter',
            data: {
                'entity id': data.id,
            },
        });
    };

    // Создали Проект из модального окна
    projectCreatedCallback = (data: ProjectInfoRead) => {
        const { businessAccountId, push } = this.props;
        push(`/${businessAccountId}/operation`);
        this.setState({
            project: createLabeledValue(data.id, data.shortName),
            projectObj: data,
            renter: createLabeledValue(data.renterId, data.renterShortName),
            ...this.getProjectParams(data),
        });
        logEvent({
            type: 'create project',
            data: {
                'entity id': data.id,
            },
        });
    };

    // Создали Поставку из модального окна
    shippingCreatedCallback = (data: SubrentInfoRead) => {
        const { businessAccountId, push } = this.props;
        push(`/${businessAccountId}/operation`);
        this.setState({
            project: createLabeledValue(data.id, data.shortName),
            projectObj: data,
            renter: createLabeledValue(data.supplierId, data.supplierShortName),
            ...this.getProjectParams(data),
        });
        logEvent({
            type: 'create project',
            data: {
                'entity id': data.id,
            },
        });
    };

    createOperation = async (type: OperationTypeCodeEnum, correctionTarget: RentStateCodeEnum | undefined = undefined) => {
        let setActualDateRes: { setActualDate: boolean; recalculateShiftCount: boolean } = {
            setActualDate: false,
            recalculateShiftCount: false,
        };

        if (this.state.renter && this.state.project) {
            let createOperation = true;
            try {
                createOperation = await listConcurrentOperationsAndShowConfirm(
                    this.props.intl,
                    this.props.businessAccountId,
                    +this.state.project.key,
                    this.state.rentType === RentTypeEnum.RENT
                        ? RentActivityFrameTypeCodeEnum.PROJECT
                        : RentActivityFrameTypeCodeEnum.SUBRENT
                );
            } catch (e) {
                return;
            }

            if (createOperation) {
                if (type === OperationTypeCodeEnum.RENT || type === OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT) {
                    setActualDateRes = await showActualizeDatesConfirm(
                        this.props.intl,
                        'Установить текущую дату выдачи?',
                        type === OperationTypeCodeEnum.RENT ? 'rent' : 'subrentAcceptShipment'
                    );
                    if (setActualDateRes.setActualDate) {
                        const startDate = this.state.defaultStartDate.clone();
                        const endDate = this.state.endDate as moment.Moment;
                        const shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  undefined,
                                  startDate,
                                  endDate,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : this.state.shiftCount;
                        await this.setState({ startDate, endDate, shiftCount });
                    }
                } else if (type === OperationTypeCodeEnum.RETURN || type === OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER) {
                    setActualDateRes = await showActualizeDatesConfirm(
                        this.props.intl,
                        'Установить текущую дату возврата?',
                        type === OperationTypeCodeEnum.RETURN ? 'return' : 'subrentReturnToShipper'
                    );
                    if (setActualDateRes.setActualDate) {
                        const startDate = this.state.startDate as moment.Moment;
                        const endDate = this.state.defaultStartDate.clone();
                        let shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  undefined,
                                  startDate,
                                  endDate,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : this.state.shiftCount;
                        await this.setState({ startDate, endDate, shiftCount });
                    }
                }

                let projectId: number = +this.state.project.key,
                    projectName: string = '' + this.state.project.label,
                    renterId: number = +this.state.renter.key,
                    renterName: string = '' + this.state.renter.label;

                this.setState(
                    {
                        isLoading: true,
                        operationTypeCode: type,
                        targetTypeCode: correctionTarget,
                    },
                    () => {
                        this.newOperation(
                            type,
                            projectId,
                            projectName,
                            renterId,
                            renterName,
                            setActualDateRes.recalculateShiftCount,
                            setActualDateRes.setActualDate
                        );
                    }
                );
            }
        }
    };

    onRentTypeChanged = (e: RadioChangeEvent) => {
        this.setState({
            rentType: e.target.value as RentTypeEnum,

            renter: undefined,
            renterObj: undefined,
            project: undefined,
            projectObj: undefined,

            startDate: undefined,
            endDate: undefined,
            shiftCount: undefined,
            discount: undefined,
        });
    };

    renderButtons = () => {
        let { operationTypeCode, isLoading, renter, project, projectObj } = this.state,
            { subrentForShortage } = this.props,
            buttonsDisabled = !renter || !project;

        let buttonsData: IOperationTypeData[] = [];
        let otherButtonsData: IOperationTypeData[] = [];
        let correctionButtonsData: IRentStateData[] = [];

        if (
            !subrentForShortage &&
            this.state.rentType === RentTypeEnum.RENT &&
            projectObj?.stateCode !== ProjectStateCodeEnum.COORDINATION
        ) {
            buttonsData = getOperationTypeDataByCodes([
                OperationTypeCodeEnum.ORDER,
                OperationTypeCodeEnum.BOOK,
                OperationTypeCodeEnum.RENT,
            ]);
            otherButtonsData = getOperationTypeDataByCodes([
                OperationTypeCodeEnum.RETURN,
                OperationTypeCodeEnum.DRAFT,
                this.props.canCreateCorrectionOperation ? OperationTypeCodeEnum.CORRECT : undefined,
            ]);
            correctionButtonsData = getRentStateDataByCodes(RENT_STATE_CODES);
        } else if (
            !subrentForShortage &&
            this.state.rentType === RentTypeEnum.RENT &&
            projectObj?.stateCode === ProjectStateCodeEnum.COORDINATION
        ) {
            buttonsData = getOperationTypeDataByCodes([
                OperationTypeCodeEnum.DRAFT,
                OperationTypeCodeEnum.ORDER,
                OperationTypeCodeEnum.BOOK,
            ]);
            otherButtonsData = getOperationTypeDataByCodes([
                this.props.canCreateCorrectionOperation ? OperationTypeCodeEnum.CORRECT : undefined,
            ]);
            correctionButtonsData = getRentStateDataByCodes(RENT_STATE_CODES);
        } else if (!subrentForShortage && this.state.rentType === RentTypeEnum.SUBRENT) {
            buttonsData = getOperationTypeDataByCodes([
                OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT,
                OperationTypeCodeEnum.SUBRENTACCEPTSHIPMENT,
            ]);
            otherButtonsData = getOperationTypeDataByCodes([
                OperationTypeCodeEnum.SUBRENTRETURNTOSHIPPER,
                OperationTypeCodeEnum.SUBRENTDRAFT,
                this.props.canCreateCorrectionOperation ? OperationTypeCodeEnum.CORRECT : undefined,
            ]);
            correctionButtonsData = getRentStateDataByCodes(SUBRENT_STATE_CODES);
        } else if (subrentForShortage && this.state.rentType === RentTypeEnum.SUBRENT) {
            buttonsData = getOperationTypeDataByCodes([OperationTypeCodeEnum.SUBRENTBOOKSHIPMENT]);
        }

        if (
            this.state.projectObj &&
            ((this.state.rentType === RentTypeEnum.RENT && this.state.projectObj.stateCode === ProjectStateCodeEnum.DRAFT) ||
                (this.state.rentType === RentTypeEnum.SUBRENT && this.state.projectObj.stateCode === SubrentStateCodeEnum.DRAFT))
        ) {
            buttonsData = getOperationTypeDataByCodes([
                this.state.rentType === RentTypeEnum.RENT ? OperationTypeCodeEnum.DRAFT : OperationTypeCodeEnum.SUBRENTDRAFT,
            ]);
            otherButtonsData = [];
            correctionButtonsData = [];
        }

        return (
            <>
                {buttonsData.map((buttonData) => (
                    <RRoundButton
                        loading={isLoading && operationTypeCode === buttonData.code}
                        disabled={buttonsDisabled}
                        colorScheme={buttonData.colorScheme as any}
                        onClick={() => {
                            this.createOperation(buttonData.code);
                        }}
                        title={buttonData.localizationCode}
                        icon={buttonData.icon}
                    />
                ))}
                {otherButtonsData.length ? (
                    <Popover
                        arrowPointAtCenter={true}
                        overlayClassName={'rr-actions-popover'}
                        placement="topRight"
                        trigger={'click'}
                        overlayStyle={{ width: 300 }}
                        content={
                            <div className={'rr-grid-actions-popover-content'}>
                                {otherButtonsData.map((buttonData) =>
                                    buttonData.code !== OperationTypeCodeEnum.CORRECT ? (
                                        <Button
                                            className={'ant-btn-page-actions'}
                                            onClick={(e) => {
                                                this.createOperation(buttonData.code);
                                            }}
                                        >
                                            <Icon
                                                component={buttonData.icon}
                                                className={'rr-operation-status-color-' + buttonData.colorScheme}
                                            />
                                            {localize(buttonData.localizationCode, 'span')}
                                        </Button>
                                    ) : (
                                        <CorrectionTargetStateSelectPopover
                                            data={correctionButtonsData}
                                            onSelect={(type: RentStateCodeEnum) => {
                                                this.createOperation(OperationTypeCodeEnum.CORRECT, type);
                                            }}
                                        >
                                            <Button
                                                className={'ant-btn-page-actions'}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                <Icon
                                                    component={buttonData.icon}
                                                    className={'rr-operation-status-color-' + buttonData.colorScheme}
                                                />
                                                {localize(buttonData.localizationCode, 'span')}
                                            </Button>
                                        </CorrectionTargetStateSelectPopover>
                                    )
                                )}
                            </div>
                        }
                    >
                        <AdditionalEntityActionsButton disabled={buttonsDisabled} />
                    </Popover>
                ) : null}
            </>
        );
    };

    render() {
        let { isLoading } = this.state,
            { businessAccountId, subrentForShortage, canViewFinancialData, subrentModuleEnabled, offersModuleEnabled } = this.props;
        let newShiftCount =
            this.state.startDate && this.state.endDate
                ? getShiftCountFromDates(
                      undefined,
                      this.state.startDate,
                      this.state.endDate,
                      this.props.defaultShiftLengthInMinutes,
                      this.props.shiftCountRoundingType
                  )
                : 0;
        let controlsDisabled =
            (this.state.rentType === RentTypeEnum.RENT && !this.state.projectObj) ||
            (this.state.rentType === RentTypeEnum.SUBRENT && !this.state.projectObj);

        let showResetShiftCountButton = !controlsDisabled && this.state.shiftCount !== undefined;

        return (
            <>
                <Row type="flex" justify="center">
                    <Col className={'rr-operationForm-page-request'} span={24} style={{ maxWidth: 850 }}>
                        <Spin spinning={isLoading}>
                            <Card bodyStyle={{ padding: 0 }}>
                                <div className="ant-modal-header">
                                    <div className="ant-modal-title">
                                        {!subrentForShortage
                                            ? localize(LocalizationEnum.PAGE__NEW_OPERATION__FAST_CREATION_FORM__FORM_TITLE)
                                            : 'Создание субаренды по нехватке'}
                                    </div>
                                </div>

                                <Row style={{ padding: 30, paddingBottom: 10, borderBottom: '1px solid #ebedf2' }}>
                                    {subrentModuleEnabled && !subrentForShortage ? (
                                        <>
                                            <div className="rr-label" style={{ marginBottom: 7 }}>
                                                Тип
                                            </div>

                                            <Col style={{ marginBottom: 19 }}>
                                                <div>
                                                    <Radio.Group
                                                        style={{ width: '100%' }}
                                                        className={'rr-select-gray'}
                                                        value={this.state.rentType}
                                                        onChange={this.onRentTypeChanged}
                                                    >
                                                        <Radio value={RentTypeEnum.RENT}>Аренда</Radio>
                                                        <Radio value={RentTypeEnum.SUBRENT}>Поставка для субаренды</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </Col>
                                        </>
                                    ) : null}

                                    <div className="rr-label" style={{ marginBottom: 7 }}>
                                        {this.state.rentType === RentTypeEnum.RENT
                                            ? localize(LocalizationEnum.ASPECT__ENTITY_TYPE__RENTER)
                                            : this.state.rentType === RentTypeEnum.SUBRENT
                                            ? 'Поставщик'
                                            : undefined}
                                    </div>

                                    <Col style={{ display: 'flex', alignItems: 'center', marginBottom: 19 }}>
                                        <div style={{ flex: 'auto' }}>
                                            <EntityRemoteSelect
                                                style={{ width: '100%' }}
                                                getEntityOperationName={'getRenterById'}
                                                placeholder={localize(
                                                    LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__MALE_GENDER
                                                )}
                                                nameField={'shortName'}
                                                onChange={this.onRenterSelectChange}
                                                className={'rr-select-gray'}
                                                showSearch={true}
                                                operationName={'listRenters'}
                                                value={this.state.renter}
                                                filters={[
                                                    `archive;EQ;false`,
                                                    this.state.rentType === RentTypeEnum.SUBRENT
                                                        ? `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.SUPPLIERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`
                                                        : `activityTypeCode;IN;${CounterpartyActivityTypeCodeEnum.RENTERONLY};${CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER}`,
                                                ]}
                                                sortOrder={'DESC'}
                                                sortBy={'lastActivityDate'}
                                                renderer={({ id, name, data: { stateCode } }) => {
                                                    const isActive = stateCode === RenterStateCodeEnum.ACTIVE;
                                                    const isBlocked = stateCode === RenterStateCodeEnum.BLOCKED;

                                                    return (
                                                        <Select.Option disabled={isBlocked} key={id}>
                                                            <div>
                                                                {!isActive && (
                                                                    <Dot
                                                                        className={`rr-status-bg-` + stateCode}
                                                                        style={{ marginRight: 10 }}
                                                                    />
                                                                )}
                                                                {name}
                                                            </div>
                                                        </Select.Option>
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: 32 }}>
                                            <Link to={`/${businessAccountId}/operation/newRenter`}>
                                                <RRoundButton
                                                    title={LocalizationEnum.ASPECT__ACTION__CREATE}
                                                    colorScheme={'success2'}
                                                    icon={IconUserPlus}
                                                />
                                            </Link>
                                        </div>
                                    </Col>

                                    <div className="rr-label" style={{ marginBottom: 7 }}>
                                        {this.state.rentType === RentTypeEnum.RENT
                                            ? localize(LocalizationEnum.ASPECT__ENTITY_TYPE__PROJECT)
                                            : 'Поставка'}
                                    </div>

                                    <Col style={{ display: 'flex', alignItems: 'center', marginBottom: 19 + 9 }}>
                                        <div style={{ flex: 'auto' }}>
                                            <EntityRemoteSelect
                                                style={{ width: '100%' }}
                                                getEntityOperationName={
                                                    this.state.rentType === RentTypeEnum.SUBRENT ? 'getSubrentById' : 'getProjectById'
                                                }
                                                placeholder={localize(
                                                    this.state.rentType === RentTypeEnum.SUBRENT
                                                        ? LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__FEMALE_GENDER
                                                        : LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__NOT_SELECTED__MALE_GENDER
                                                )}
                                                nameField={'shortName'}
                                                onChange={this.onProjectSelectChange}
                                                //onLabelChange={this.onProjectLabelChange}
                                                className={'rr-select-gray'}
                                                showSearch={true}
                                                operationName={
                                                    this.state.rentType === RentTypeEnum.SUBRENT ? 'listSubrents' : 'listProjects'
                                                }
                                                value={this.state.project}
                                                disabled={!this.state.renter}
                                                filters={
                                                    this.state && this.state.renter && this.state.renter.key
                                                        ? ([
                                                              this.state.rentType === RentTypeEnum.SUBRENT
                                                                  ? `supplierId;EQ;${this.state.renter.key}`
                                                                  : `renterId;EQ;${this.state.renter.key}`,
                                                              `archive;EQ;false`,
                                                              this.state.rentType === RentTypeEnum.RENT
                                                                  ? `stateCode;IN;${ProjectStateCodeEnum.INPROGRESS};${ProjectStateCodeEnum.DRAFT};${ProjectStateCodeEnum.COORDINATION}`
                                                                  : `stateCode;IN;${SubrentStateCodeEnum.INPROGRESS};${SubrentStateCodeEnum.DRAFT}`,
                                                              this.state.rentType === RentTypeEnum.RENT
                                                                  ? 'isSimpleOrder;EQ;false'
                                                                  : undefined,
                                                              this.state.rentType === RentTypeEnum.RENT
                                                                  ? `projectType;EQ;${ProjectTypeCodeEnum.BASE}`
                                                                  : undefined,
                                                          ].filter((f) => f) as string[])
                                                        : undefined
                                                }
                                                sortOrder={'DESC'}
                                                sortBy={'lastActivityDate'}
                                                renderer={(data, index, value) => {
                                                    const record: ProjectRecord | SubrentRecord = data.data;

                                                    let badge: ReactNode;
                                                    if (
                                                        this.state.rentType === RentTypeEnum.RENT &&
                                                        (record.stateCode === ProjectStateCodeEnum.DRAFT ||
                                                            record.stateCode === ProjectStateCodeEnum.COORDINATION)
                                                    ) {
                                                        badge = <ProjectStateBadge type={record.stateCode} dot={true} hideLabel={true} />;
                                                    } else if (
                                                        this.state.rentType === RentTypeEnum.SUBRENT &&
                                                        record.stateCode === SubrentStateCodeEnum.DRAFT
                                                    ) {
                                                        badge = (
                                                            <SubrentShippingStateBadge
                                                                type={record.stateCode}
                                                                dot={true}
                                                                hideLabel={true}
                                                            />
                                                        );
                                                    }
                                                    return (
                                                        <Select.Option key={data['id']}>
                                                            {badge}
                                                            {data['name']}
                                                        </Select.Option>
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginLeft: 32 }}>
                                            <Link
                                                to={
                                                    this.state.rentType === RentTypeEnum.RENT
                                                        ? `/${businessAccountId}/operation/newProject`
                                                        : `/${businessAccountId}/operation/newShipping`
                                                }
                                                style={{ float: 'right' }}
                                            >
                                                <RRoundButton
                                                    title={LocalizationEnum.ASPECT__ACTION__CREATE}
                                                    colorScheme={'success2'}
                                                    icon={this.state.rentType === RentTypeEnum.RENT ? IconMedkit : IconDollySolid}
                                                />
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>

                                {subrentForShortage ? (
                                    <Row gutter={0} style={{ padding: '0px 30px 26px 30px' }}>
                                        <Col className={'rr-form-group'}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {/*style={{fontSize:22, marginRight: 12, marginTop: 1, verticalAlign: 'top', color: '#8d5bae'}}*/}
                                                <Icon
                                                    component={IconArrowsAltHSolid}
                                                    style={{ fontSize: 22, color: '#8d5bae', marginRight: 10 }}
                                                />
                                                <span style={{ fontSize: 18, fontWeight: 700 }}>Запас времени</span>
                                            </div>
                                            <div style={{ display: 'inline-block', marginTop: 18, marginBottom: -18 }}>
                                                {/* Часов до выдачи */}
                                                <div className="rr-form-item" style={{ display: 'inline-block', marginRight: 33 }}>
                                                    <div className="rr-label" style={{ marginBottom: 7 }}>
                                                        <span>Часов до выдачи</span>
                                                    </div>
                                                    <div className={'rr-value'}>
                                                        <NumberInput
                                                            //defaultValue={this.state.subrentHoursBeforeRent}
                                                            value={this.state.subrentHoursBeforeRent}
                                                            onChange={this.onSubrentHoursBeforeRentChanged}
                                                            min={0}
                                                        />
                                                    </div>
                                                </div>
                                                {/* Часов после возврата */}
                                                <div className="rr-form-item" style={{ display: 'inline-block' }}>
                                                    <div className="rr-label" style={{ marginBottom: 7 }}>
                                                        <span>Часов после возврата</span>
                                                    </div>
                                                    <div className={'rr-value'}>
                                                        <NumberInput
                                                            //defaultValue={this.state.subrentHoursAfterReturn}
                                                            value={this.state.subrentHoursAfterReturn}
                                                            onChange={this.onSubrentHoursAfterReturn}
                                                            min={0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}

                                {!subrentForShortage ? (
                                    <Row gutter={0} style={{ padding: '0px 30px 26px 30px' }}>
                                        <Col className={'rr-form-group'}>
                                            <div>
                                                <h3>
                                                    {localize(LocalizationEnum.PAGE__NEW_OPERATION__TAB__COMPOSITION__DEFAULT_RENT_TERMS)}
                                                </h3>
                                            </div>

                                            <div style={{ display: 'inline-block', marginRight: 60 }}>
                                                {/* Период аренды */}
                                                <div className="rr-form-item">
                                                    <div className="rr-label" style={{ marginBottom: 7 }}>
                                                        <span>{localize(LocalizationEnum.ASPECT__RENT_BUSINESS__RENT_PERIOD)}</span>
                                                    </div>
                                                    <div className="rr-value">
                                                        {/*<CustomRangePicker*/}
                                                        {/*    disabled={controlsDisabled}*/}
                                                        {/*    style={{ width: 478 }}*/}
                                                        {/*    align={{ points: ['bc', 'tc'], offset: [0, -6] }}*/}
                                                        {/*    allowClear={false}*/}
                                                        {/*    // defaultValue={*/}
                                                        {/*    //     this.state.startDate && this.state.endDate*/}
                                                        {/*    //         ? [this.state.startDate, this.state.endDate]*/}
                                                        {/*    //         : undefined*/}
                                                        {/*    // }*/}
                                                        {/*    value={*/}
                                                        {/*        this.state.startDate && this.state.endDate*/}
                                                        {/*            ? [this.state.startDate, this.state.endDate]*/}
                                                        {/*            : [undefined, undefined]*/}
                                                        {/*    }*/}
                                                        {/*    //className={'rr-input-gray'}*/}
                                                        {/*    showTime*/}
                                                        {/*    onChange={this.onRentPeriodChanged}*/}
                                                        {/*    shiftLengthInMin={this.props.defaultShiftLengthInMinutes}*/}
                                                        {/*    hasOwnShiftLength={false}*/}
                                                        {/*    requiredTimeIndentBetweenElementsInMinutes={*/}
                                                        {/*        this.props.requiredTimeIndentBetweenElementsInMinutes*/}
                                                        {/*    }*/}
                                                        {/*    productHasOwnRequiredTimeIndentBetweenElements={false}*/}
                                                        {/*    startDatePlaceholder={*/}
                                                        {/*        this.state.rentType === RentTypeEnum.RENT ? 'Дата выдачи' : 'Дата приема'*/}
                                                        {/*    }*/}
                                                        {/*    endDatePlaceholder={'Дата возврата'}*/}
                                                        {/*    shiftCount={this.state.shiftCount}*/}
                                                        {/*/>*/}
                                                        <RangePicker
                                                            disabled={controlsDisabled}
                                                            style={{ width: 478 }}
                                                            allowClear={false}
                                                            value={
                                                                this.state.startDate && this.state.endDate
                                                                    ? [this.state.startDate, this.state.endDate]
                                                                    : undefined //[undefined, undefined]
                                                            }
                                                            showTime
                                                            onChange={this.onRentPeriodChanged as any}
                                                            placeholder={[
                                                                this.state.rentType === RentTypeEnum.RENT ? 'Дата выдачи' : 'Дата приема',
                                                                'Дата возврата',
                                                            ]}
                                                            shiftCount={this.state.shiftCount}
                                                            requiredTimeIndentProps={{
                                                                minutes: this.props.requiredTimeIndentBetweenElementsInMinutes,
                                                                hasOwnIndent: false,
                                                            }}
                                                            recalculateShiftCountBlockProps={{
                                                                // recalculateShiftCount: false,
                                                                // recalculateShiftCountOnChange: (recalculate: boolean) => {
                                                                //
                                                                // },
                                                                shiftLengthInMin: this.props.defaultShiftLengthInMinutes,
                                                                hasOwnShiftLength: false,
                                                                shiftLengthHint: undefined,
                                                                fromShiftCount: this.state.shiftCount,
                                                            }}
                                                            pricingScheme={undefined}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'inline-block', marginTop: 18, marginBottom: -18 }}>
                                                {/* Число смен */}
                                                <div className="rr-form-item" style={{ display: 'inline-block', marginRight: 33 }}>
                                                    <div className="rr-label" style={{ marginBottom: 7 }}>
                                                        <span>{localize(LocalizationEnum.PAGE__OPERATIONS__CARD__SHIFT_COUNT)}</span>
                                                    </div>
                                                    <div className={'rr-value'}>
                                                        <NumberInputShiftCount
                                                            disabled={controlsDisabled}
                                                            value={this.state.shiftCount}
                                                            onChange={this.onShiftCountChanged}
                                                        />
                                                        <RecalculateShiftsCountButton
                                                            hasOwnShiftLength={false}
                                                            shiftLengthInMinutes={this.props.defaultShiftLengthInMinutes}
                                                            style={{ marginLeft: 10 }}
                                                            from={this.state.shiftCount}
                                                            to={newShiftCount}
                                                            onClick={() => {
                                                                this.setState({ shiftCount: newShiftCount });
                                                            }}
                                                        />
                                                        {
                                                            <span style={{ opacity: showResetShiftCountButton ? 1 : 0 }}>
                                                                <Icon
                                                                    component={IconClose}
                                                                    style={{ marginTop: -2, marginLeft: 4 }}
                                                                    className={'rr-operationForm-elements-top-block__reset'}
                                                                    onClick={
                                                                        showResetShiftCountButton
                                                                            ? () => {
                                                                                  this.setState({
                                                                                      shiftCount: undefined,
                                                                                  });
                                                                              }
                                                                            : undefined
                                                                    }
                                                                />
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                                {/* Итоговая скидка */}
                                                {canViewFinancialData && (
                                                    <div className="rr-form-item" style={{ display: 'inline-block' }}>
                                                        <div className="rr-label" style={{ marginBottom: 7 }}>
                                                            <span>Скидка</span>
                                                        </div>
                                                        <div className={'rr-value'}>
                                                            <NumberInputDiscount
                                                                disabled={controlsDisabled}
                                                                value={this.state.discount}
                                                                onChange={this.onDiscountChanged}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                ) : null}

                                <Row>
                                    <Col className={'rr-operationForm-page-request__create-operation-buttons'}>{this.renderButtons()}</Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                </Row>

                {/* Окно создания нового контрагента */}
                <Route
                    exact
                    path={`/${businessAccountId}/operation/newRenter`}
                    render={(props) => (
                        <CounterpartyCreateModalNew
                            counterpartyType={this.state.rentType === RentTypeEnum.RENT ? 'renter' : 'supplier'}
                            onSuccess={(data) => {
                                this.renterCreatedCallback(data);
                            }}
                            onCancel={() => {
                                this.props.goBack();
                            }}
                        />
                    )}
                />

                {/* Окно создания нового проекта */}
                <Route
                    exact
                    path={`/${businessAccountId}/operation/newProject`}
                    render={(props) => (
                        <ProjectCreateModal
                            editMode={false}
                            title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__CREATE)}
                            okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                            successCallback={this.projectCreatedCallback}
                            businessAccountId={businessAccountId}
                            renter={this.state.renter}
                            renterObj={this.state.renterObj}
                            createFromOperationForm
                            filters={createProjectModalFilters}
                            modalName={'create-project'}
                        />
                    )}
                />

                {/* Окно создания новой поставки */}
                <Route
                    exact
                    path={`/${businessAccountId}/operation/newShipping`}
                    render={(props) => (
                        <SubrentShippingCreateModal
                            editMode={false}
                            renterId={this.state.renter?.key}
                            renterObj={this.state.renterObj}
                            title={localize(LocalizationEnum.PAGE__SHIPPINGS__FORM__FORM_TITLE__CREATE)}
                            businessAccountId={businessAccountId}
                            successCallback={this.shippingCreatedCallback}
                            createFromOperationForm
                            filters={createSubrentShippingSchemeModalFilters}
                            modalName={'create-subrent_shipping'}
                        />
                    )}
                />
            </>
        );
    }

    onRentPeriodChanged = (dates: RangePickerValue) => {
        let shiftCount =
            dates[0] && dates[1]
                ? getShiftCountFromDates(
                      undefined,
                      dates[0],
                      dates[1],
                      this.props.defaultShiftLengthInMinutes,
                      this.props.shiftCountRoundingType
                  )
                : 0;
        this.setState({
            startDate: dates[0],
            endDate: dates[1],
            shiftCount: this.props.recalculateShiftCount ? shiftCount : this.state.shiftCount,
        });
    };

    onShiftCountChanged = (value: number | undefined) => {
        this.setState({ shiftCount: value });
    };

    onDiscountChanged = (value: number | undefined) => {
        this.setState({ discount: value });
    };

    onSubrentHoursBeforeRentChanged = (value: number | undefined) => {
        this.setState({ subrentHoursBeforeRent: value });
        const data = this.getStorageData();
        data.subrentHoursBeforeRent = value;
        this.setStorageData(data);
    };

    onSubrentHoursAfterReturn = (value: number | undefined) => {
        this.setState({ subrentHoursAfterReturn: value });
        const data = this.getStorageData();
        data.subrentHoursAfterReturn = value;
        this.setStorageData(data);
    };

    getStorageKey = () => {
        return 'operation-form' + '_' + this.props.userEmail + '_' + this.props.businessAccountId;
    };

    getStorageData = () => {
        let key = this.getStorageKey();
        return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '{}') : {};
    };

    setStorageData = (data: any) => {
        let key = this.getStorageKey();
        localStorage.setItem(key, JSON.stringify(data));
    };

    private async newOperation(
        type: OperationTypeCodeEnum,
        projectId: number,
        projectName: string,
        renterId: number,
        renterName: string,
        recalculateShiftCount: boolean,
        setActualDate: boolean
    ) {
        let taxRate: number | undefined;
        let taxBaseType: TaxBaseTypeCodeEnum | undefined;

        try {
            if (this.props.canViewFinancialData) {
                if (this.state.rentType === RentTypeEnum.RENT) {
                    let project = await serverApi.getProjectById(this.props.businessAccountId, projectId);
                    taxRate = project.data.taxRate;
                    taxBaseType = project.data.taxBaseType;
                }
            }

            let elements = this.props.subrentForShortage ? getStoreState().operationForm.elements.entities : [];
            if (this.props.subrentForShortage) {
                elements = elements.map((el) => {
                    el.rentPeriodStartDate = moment(el.rentPeriodStartDate)
                        .add(-(this.state.subrentHoursBeforeRent || 0), 'h')
                        .toDate();
                    el.rentPeriodEndDate = moment(el.rentPeriodEndDate)
                        .add(this.state.subrentHoursAfterReturn || 0, 'h')
                        .toDate();
                    return el;
                });
            }

            this.setState({ isLoading: false });
            if (this.props.subrentForShortage) this.props.push(`/${this.props.businessAccountId}/operation?tab=elements`);
            else this.props.push(`/${this.props.businessAccountId}/operation?tab=products`);
            this.props.startNewOperation(
                undefined,
                taxRate,
                taxBaseType,
                false,
                renterId,
                renterName,
                projectId,
                projectName,
                undefined,
                type,
                elements,
                'mnemoKey',
                true,
                this.state.discount,
                this.state.targetTypeCode,
                undefined,
                undefined,
                [],
                {},
                setActualDate,
                recalculateShiftCount,
                this.state.shiftCount,
                this.state.startDate,
                this.state.endDate,
                this.state.defaultStartDate
            );
        } catch (e) {
            this.setState({ isLoading: false });
            showNotification(
                'error',
                <>
                    {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR)}.<br />{' '}
                    {localizeIntl(this.props.intl, LocalizationEnum.ASPECT__POPUP_NOTIFICATIONS__TRY_TO_REPEAT_YOUR_REQUEST_AGAIN)}
                </>
            );
        }
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        userEmail: storeState.oidc.user?.profile.email,
        businessAccountId: storeState.system.businessAccountId,
        projectId: storeState.operationForm.projectId,
        projectShortName: storeState.operationForm.projectShortName,
        subrentForShortage: storeState.operationForm.subrentForShortage,
        renterId: storeState.operationForm.renterId,
        renterShortName: storeState.operationForm.renterShortName,
        isSubrent: storeState.operationForm.isSubrent,
        discount: storeState.operationForm.discount,
        recalculateShiftCount: storeState.userSettings.operationFormRecalculateShiftCount,
        defaultShiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        requiredTimeIndentBetweenElementsInMinutes:
            storeState.businessAccountPreferences.preferences?.requiredTimeIndentBetweenElementsInMinutes,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canCreateCorrectionOperation: canCreateCorrectionOperation(storeState.permissions.permissions),
        subrentModuleEnabled: subrentModuleEnabledSelector(storeState),
        offersModuleEnabled: offersModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { push, goBack, startNewOperation };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const NewOperationModule = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Component));
