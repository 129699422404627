import React from 'react';
import { FormFieldsGroup, FormItemType, SelectItem } from '../../../../../components/dynamicForm/DynamicForm';
import { EntityRemoteSelect } from '../../../../../components/select/EntityRemoteSelect';
import debounce from 'lodash/debounce';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { validateField } from '../../../../../shared/util/validateField';
import {
    ContactLinkObjWrite,
    ContractValidationTypeCodeEnum,
    CounterpartyActivityTypeCodeEnum,
    ProjectStateCodeEnum,
    RenterStateCodeEnum,
    serverApi, TypeOfPricingCodeEnum,
    UserRecord,
} from '../../../../../server';
import { getBusinessAccountId, getStoreState } from '../../../../../../index';
import { LocalizationEnum, localize } from '../../../../../localization';
import { isDefined, printUserName } from '../../../../../shared/util/utils';
import { Dot } from '../../../../../components/dot/dot';
import { getLabeledValueNumberKey } from '../../../../../shared/util/utils5';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { createHiddenField } from '../../../../../components/modalForm/utils';
import { DefaultTaxContent } from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFieldsData';
import { defaultTaxCalculationTypeField } from '../../../settings/businessAccountPreferences/businessAccountPreferencesEditModalFields';
import {
    CounterpartySelectAndCreate,
    CounterpartySelectAndCreateProps,
} from '../../../../../components/v2/select/counterpartySelect/counterpartySelectAndCreate';
import { LocationSelectAndCreate } from '../../../../../components/v2/select/locationSelect/locationSelectAndCreate';
import {
    DatePickerWithManualInput,
    DatePickerWithManualInputProps,
} from '../../../../../components/datePicker/DatePickerWithManualInput/DatePickerWithManualInput';
import {
    datePickerFormValidatorFunction,
    validateDatePickerFormValue,
} from '../../../../../components/datePicker/DatePickerWithManualInput/helpers/formValidatorFunction';
import {
    contractValidationTypeSelector,
    offersModuleEnabledSelector,
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import {
    ContactData,
    ContactsEditor,
    ContactsEditorProps
} from "../../../../../components/contactsEditor/contactsEditor";
import { canUpdateFinancialDataSelector } from '../../../../../shared/reducers/permissions.reducer';
import { RangePickerProps } from '../../../../../components/v2/calendar/rangePicker/rangePicker';
import { CopyProjectParamsBlock } from '../components/copyProjectParamsBlock/copyProjectParamsBlock';
import { Moment } from 'moment';
import { Select } from 'antd';
import UserDetailedOption from '../../../../../components/v2/detailedSelect/UserDetailedOption';
import {PricingTypeSelect} from "./pricingTypeSelect";

export const projectContractFields = [
    {
        label: 'Номер договора',
        id: 'projectContract.contractNumber',
        maxLength: 30,
        type: FormItemType.String,
    },
    {
        label: 'Дата договора',
        id: 'projectContract.contractDate',
        type: FormItemType.Component,
        component: DatePickerWithManualInput,
        componentProps: {
            suppressedModes: ['decade'] as DatePickerWithManualInputProps['suppressedModes'],
            defaultPickerMode: 'day' as DatePickerWithManualInputProps['defaultPickerMode'],
        },
        validationFunction: debounce((fieldName: string, value: any, cb: Function, form) => {
            datePickerFormValidatorFunction(fieldName, value, cb);
        }, 500),
        onChange: (value, form) => {
            setTimeout(() => {
                form.validateFields(['projectContract.contractExpirationDate']);
            }, 100);
        },
    },
    {
        label: 'Дата окончания действия договора',
        id: 'projectContract.contractExpirationDate',
        type: FormItemType.Component,
        component: DatePickerWithManualInput,
        componentProps: {
            suppressedModes: ['decade'] as DatePickerWithManualInputProps['suppressedModes'],
            defaultPickerMode: 'day' as DatePickerWithManualInputProps['defaultPickerMode'],
        },
        validationFunction: debounce((fieldName: string, value: Moment, cb: Function, form: WrappedFormUtils) => {
            const contractDate: Moment | undefined = form.getFieldValue('projectContract.contractDate');
            if (value) {
                const dateIsValid = validateDatePickerFormValue(value);
                if (!dateIsValid) cb('Некорректный формат даты');
                else {
                    if (contractDate) {
                        if (value.valueOf() <= contractDate.valueOf()) cb('Дата окончания действия должна быть позже даты договора');
                        else cb();
                    } else {
                        cb();
                    }
                }
            } else cb();
        }, 500),
    },
];

// dynamic form fields
export const formFields: FormFieldsGroup[] = [
    {
        fields: [createHiddenField('fromTemplate'), createHiddenField('copyMode'), createHiddenField('totalNotCanceledOffersCount'), createHiddenField('projectTypeOfPricing')],
    },
    {
        fields: [
            {
                label: LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TEMPLATE,
                id: 'template',
                type: FormItemType.String,
                disabled: true,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('fromTemplate') === true;
        },
    },
    {
        fields: [
            {
                id: 'copyParams',
                type: FormItemType.Component,
                component: CopyProjectParamsBlock,
            },
        ],
        visible: (form) => {
            return form.getFieldValue('copyMode') === true;
        },
    },
    {
        fields: [
            createHiddenField('id'),
            createHiddenField('renterStateCode'),
            createHiddenField('projectEdit'),
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__SHORT_TITLE,
                id: 'shortName',
                type: FormItemType.String,
                required: true,
                maxLength: 50,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    const renterId = getLabeledValueNumberKey(form.getFieldValue('renterId'));

                    if (renterId) {
                        const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;

                        validateField(
                            'project',
                            fieldName,
                            value,
                            renterId,
                            ignoreCurrentId,
                            'Краткое наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__FULL_NAME,
                id: 'fullName',
                type: FormItemType.String,
                required: false,
                maxLength: 255,
                validationFunction: debounce((fieldName: string, value: any, cb: Function, form: WrappedFormUtils) => {
                    let renterId = getLabeledValueNumberKey(form.getFieldValue('renterId'));
                    if (renterId) {
                        const ignoreCurrentId = form.getFieldValue('projectEdit') ? form.getFieldValue('id') : undefined;
                        validateField(
                            'project',
                            fieldName,
                            value,
                            renterId,
                            ignoreCurrentId,
                            'Полное наименование уже используется в текущем арендаторе',
                            cb
                        );
                    } else cb();
                }, 500),
            },
            {
                label: LocalizationEnum.ASPECT__ENTITY_TYPE__RENTER,
                id: 'renterId',
                type: FormItemType.Component,
                required: true,
                component: CounterpartySelectAndCreate,
                componentProps: (form: WrappedFormUtils): CounterpartySelectAndCreateProps => {
                    return {
                        counterpartyType: 'renter',
                        filters: {
                            activityTypeCode: [
                                CounterpartyActivityTypeCodeEnum.RENTERONLY,
                                CounterpartyActivityTypeCodeEnum.RENTERANDSUPPLIER,
                            ]
                        },
                        isItemDisabled: (record) => {
                            const creation = !form.getFieldValue('id');
                            const projectStateCode:ProjectStateCodeEnum|undefined = form.getFieldValue('stateCode');
                            const disabledStates:RenterStateCodeEnum[] = [];

                            if(projectStateCode === ProjectStateCodeEnum.COORDINATION || projectStateCode === ProjectStateCodeEnum.INPROGRESS || projectStateCode === ProjectStateCodeEnum.FINISHED || (projectStateCode === ProjectStateCodeEnum.CANCELED && creation)){
                                disabledStates.push(RenterStateCodeEnum.NEW);
                            }
                            if(creation && (projectStateCode === ProjectStateCodeEnum.FINISHED || projectStateCode === ProjectStateCodeEnum.CANCELED)){
                                disabledStates.push(RenterStateCodeEnum.ACTIVE);
                            }
                            if(projectStateCode === ProjectStateCodeEnum.COORDINATION || projectStateCode === ProjectStateCodeEnum.INPROGRESS || (creation && (projectStateCode === ProjectStateCodeEnum.DRAFT || projectStateCode === ProjectStateCodeEnum.FINISHED || projectStateCode === ProjectStateCodeEnum.CANCELED))){
                                disabledStates.push(RenterStateCodeEnum.BLOCKED);
                            }
                            return disabledStates.includes(record.stateCode);
                        },
                        onDataChange: async (data) => {
                            const value = data?.[0];
                            let renterId = value?.id;

                            form.setFieldsValue({ renterStateCode: value?.stateCode });

                            if (value?.stateCode === RenterStateCodeEnum.NEW) {
                                form.setFieldsValue({ stateCode: ProjectStateCodeEnum.DRAFT });
                            }

                            let defaultAssignee;
                            let counterpartyContactLinks;

                            if (renterId) {
                                let renter = await serverApi.getRenterById(getBusinessAccountId(), renterId);

                                await form.setFieldsValue({ renterDefaultDiscount: renter.data.defaultDiscount });
                                form.setFieldsValue({counterpartyContactLinks: renter.data.contactLinks});

                                counterpartyContactLinks = renter.data.contactLinks;

                                if(renter.data.assigneeFullName){
                                    defaultAssignee = {
                                        key: renter.data.assigneeId,
                                        label: printUserName(renter.data.assigneeFullName)
                                    }
                                }

                                if (value) {
                                    if (form.getFieldValue('useRenterDefaultDiscount')) {
                                        await form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') });
                                    }

                                    if (!form.isFieldValidating('shortName') && !form.isFieldValidating('fullName')) {
                                        form.validateFields(['shortName', 'fullName']);
                                    }

                                    // if (form.getFieldValue('useAssignee')) {
                                    //     if (renter.data.assigneeFullName) {
                                    //         await form.setFieldsValue({
                                    //             assigneeId: {
                                    //                 key: renter.data.id,
                                    //                 label: renter.data.assigneeFullName
                                    //                     ? renter.data.assigneeFullName.lastname +
                                    //                       (renter.data.assigneeFullName.firstname
                                    //                           ? ' ' + renter.data.assigneeFullName.firstname
                                    //                           : '')
                                    //                     : '',
                                    //             },
                                    //         });
                                    //     } else {
                                    //         await form.setFieldsValue({
                                    //             assigneeId: undefined,
                                    //         });
                                    //     }
                                    // }
                                }
                            } else {
                                // if (form.getFieldValue('useAssignee')) {
                                //     await form.setFieldsValue({
                                //         assigneeId: undefined,
                                //     });
                                // }
                            }

                            form.setFieldsValue({counterpartyContactLinks: counterpartyContactLinks});
                            if(form.getFieldValue('hasOwnContacts')){
                                form.setFieldsValue({contactLinks: counterpartyContactLinks});
                            }

                            form.setFieldsValue({renterDefaultAssignee: defaultAssignee});
                            if(form.getFieldValue('useAssignee')){
                                form.setFieldsValue({assigneeId: defaultAssignee});
                            }

                        },
                    };
                },
                placeholder: localize(LocalizationEnum.ASPECT__FORMS__PLACEHOLDER__SELECT_RENTER),
            },
            {
                label: 'Локация',
                id: 'locationId',
                type: FormItemType.Component,
                component: LocationSelectAndCreate,
                placeholder: 'Выберите локацию',
                visible: (getFieldValue) => {
                    return getStoreState().businessAccountPreferences.preferences?.modules.logisticsModuleEnabled === true;
                },
            },
            {
                label: LocalizationEnum.ASPECT__FILTERS__STATUS,
                placeholder: localize(LocalizationEnum.ASPECT__FILTERS__PLACEHOLDER__ANY__MALE_GENDER),
                id: 'stateCode',
                type: FormItemType.Select,
                defaultValue: ProjectStateCodeEnum.INPROGRESS,
                required: true,
                values: (form: WrappedFormUtils) => {
                    let values: SelectItem[] = [];
                    let renterStateCode: string = form.getFieldValue('renterStateCode');

                    if (!form.getFieldValue('id')) {
                        // Это создание
                        const contractValidationType = contractValidationTypeSelector(getStoreState());
                        if (
                            contractValidationType === ContractValidationTypeCodeEnum.ONLYFILE ||
                            contractValidationType === ContractValidationTypeCodeEnum.ALLFIELDS
                        ) {
                            values = [
                                {
                                    name: 'Черновик',
                                    value: ProjectStateCodeEnum.DRAFT,
                                    disabled: false,
                                },
                                {
                                    name: 'Согласование',
                                    value: ProjectStateCodeEnum.COORDINATION,
                                    disabled: renterStateCode === RenterStateCodeEnum.NEW,
                                },
                            ];
                        } else {
                            values = [
                                {
                                    name: 'Черновик',
                                    value: ProjectStateCodeEnum.DRAFT,
                                    disabled: false,
                                },
                                {
                                    name: 'Согласование',
                                    value: ProjectStateCodeEnum.COORDINATION,
                                    disabled: renterStateCode === RenterStateCodeEnum.NEW,
                                },
                                {
                                    name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                                    value: ProjectStateCodeEnum.INPROGRESS,
                                    disabled: renterStateCode === RenterStateCodeEnum.NEW,
                                },
                            ];
                        }
                    } else if (form.getFieldValue('id')) {
                        // Это редактирование
                        values = [
                            {
                                name: 'Черновик',
                                value: ProjectStateCodeEnum.DRAFT,
                                disabled: true,
                            },
                            {
                                name: 'Согласование',
                                value: ProjectStateCodeEnum.COORDINATION,
                                disabled: true,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__INPROGRESS),
                                value: ProjectStateCodeEnum.INPROGRESS,
                                disabled: true,
                            },
                            {
                                name: localize(LocalizationEnum.ASPECT__STATE_CODE__PROJECT__FINISHED),
                                value: ProjectStateCodeEnum.FINISHED,
                                disabled: true,
                            },
                            {
                                name: 'Отменен',
                                value: ProjectStateCodeEnum.CANCELED,
                                disabled: true,
                            },
                        ];
                    }

                    return values.map((item) => ({
                        ...item,
                        name: (
                            <>
                                <Dot className={`rr-status-bg-` + item.value} style={{ marginRight: 10 }} /> {item.name}
                            </>
                        ),
                    }));
                },
                disabled: (getFieldValue) => !!getFieldValue('createFromOperationForm') || !!getFieldValue('id'),
            },
            {
                label: 'Период работ',
                id: 'dates',
                type: FormItemType.DateRangePicker,
                componentProps: (): RangePickerProps => ({
                    placeholder: ['Начало', 'Завершение'],
                    style: { width: 360 },
                    pricingScheme: undefined,
                }),
            },
            {
                id: 'typeOfPricing',
                type: FormItemType.Component,
                component: PricingTypeSelect,
                required: true,
                visible: (getFieldValue) => {
                    const id:number|undefined = getFieldValue('id');
                    const copyMode:boolean|undefined = getFieldValue('copyMode');
                    const projectTypeOfPricing:TypeOfPricingCodeEnum|undefined = getFieldValue('projectTypeOfPricing');
                    const totalNotCanceledOffersCount:number|undefined = getFieldValue('totalNotCanceledOffersCount');
                    const state = getStoreState();
                    const offersModuleEnabled = offersModuleEnabledSelector(state);
                    const canUpdateFinancialData = canUpdateFinancialDataSelector(state);

                    if(!id && !copyMode){
                        // Создание
                        return Boolean(offersModuleEnabled && canUpdateFinancialData);
                    }else if(id){
                        // Редактирование
                        if((!offersModuleEnabled && (projectTypeOfPricing === TypeOfPricingCodeEnum.STRAIGHT || projectTypeOfPricing === TypeOfPricingCodeEnum.STRAIGHTANDOFFERS) && !totalNotCanceledOffersCount) || !canUpdateFinancialData) return false;
                        else return true;
                    }else if(!id && copyMode){
                        // Копирование
                        return Boolean(offersModuleEnabled && canUpdateFinancialData);
                    }else return false;
                },
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__ASSIGNEE),
        fields: [
            createHiddenField('renterDefaultAssignee'),
            createHiddenField('defaultAssignee'),
            createHiddenField('_defaultAssignee'),
            createHiddenField('__tmpAssigneeId'),
            {
                label: LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER,
                id: 'useAssignee',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: async (value: any, form: WrappedFormUtils) => {
                    // if (value) {
                    //     if (form.getFieldValue('renterDefaultAssignee')) {
                    //         form.setFieldsValue({ assigneeId: form.getFieldValue('renterDefaultAssignee') });
                    //     } else {
                    //         if (form.getFieldValue('renterId')) {
                    //             let renterId = getLabeledValueNumberKey(form.getFieldValue('renterId'));
                    //             if (renterId) {
                    //                 let renter = await serverApi.getRenterById(getBusinessAccountId(), renterId);
                    //                 if (renter.data.assigneeFullName) {
                    //                     form.setFieldsValue({
                    //                         assigneeId: {
                    //                             key: renter.data.id,
                    //                             label: printUserName(renter.data.assigneeFullName),
                    //                         },
                    //                     });
                    //                 } else {
                    //                     form.setFieldsValue({ assigneeId: undefined });
                    //                 }
                    //             }
                    //         } else {
                    //             form.setFieldsValue({ assigneeId: undefined });
                    //         }
                    //     }
                    // } else {
                    //     if (form.getFieldValue('defaultAssignee')) {
                    //         form.setFieldsValue({ assigneeId: form.getFieldValue('defaultAssignee') });
                    //     }
                    // }
                    if (value){
                        // По арендатору
                        form.setFieldsValue({ __tmpAssigneeId: form.getFieldValue('assigneeId') });
                        form.setFieldsValue({ assigneeId: form.getFieldValue('renterDefaultAssignee') });
                    }else {
                        // Собственный
                        form.setFieldsValue({ assigneeId: form.getFieldValue('__tmpAssigneeId') });
                    }
                },
            },
            {
                id: 'assigneeId',
                type: FormItemType.Component,
                component: EntityRemoteSelect,
                disabled: (getFieldValue) => getFieldValue('useAssignee') === true,
                componentProps: {
                    className: 'rr-select-detailed',
                    operationName: 'listUsersSimple',
                    nameField: 'shortName',
                    renderer: (option: { id: number; name: string; data: UserRecord }) => {
                        return (
                            <Select.Option key={option.id}>
                                <UserDetailedOption item={option.data} getKey={(item) => item.id} />
                            </Select.Option>
                        );
                    },
                },
                placeholder: localize(LocalizationEnum.ASPECT__DATA_PRESENCE__NOT_ASSIGNED),
            },
        ],
    },
    {
        title: localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DEFAULT_DISCOUNT),
        visible: (form) => {
            const p = getStoreState().permissions.permissions;
            return canViewFinancialData(p);
        },
        fields: [
            createHiddenField('renterDefaultDiscount'),
            createHiddenField('_defaultDiscount'),
            {
                label: LocalizationEnum.PAGE__PROJECTS__FIELD_VALUE_BY_RENTER,
                id: 'useRenterDefaultDiscount',
                type: FormItemType.Switch,
                defaultValue: true,
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
                onChange: (value: any, form: WrappedFormUtils) => {
                    if (value) {
                        form.setFieldsValue({ _defaultDiscount: form.getFieldValue('defaultDiscount') });
                        form.setFieldsValue({ defaultDiscount: form.getFieldValue('renterDefaultDiscount') || 0 });
                    } else {
                        form.setFieldsValue({ defaultDiscount: form.getFieldValue('_defaultDiscount') });
                    }
                },
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
            },
            {
                id: 'defaultDiscount',
                type: FormItemType.Discount,
                defaultValue: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return (canViewFinancialData(p) && !canUpdateFinancialData(p)) || getFieldValue('useRenterDefaultDiscount') === true;
                },
                onChange: (value: any, form: WrappedFormUtils) => {
                    form.setFieldsValue({ _defaultDiscount: value });
                },
            },
        ],
    },
    {
        helpMessage: DefaultTaxContent,
        fields: [
            {
                label: LocalizationEnum.PAGE__PROJECTS__FORM__FIELD__TAX_PERCENT,
                id: 'taxRate',
                type: FormItemType.Tax,
                defaultValue: 0,
                required: true,
                disabled: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p) && !canUpdateFinancialData(p);
                },
                visible: (getFieldValue) => {
                    const p = getStoreState().permissions.permissions;
                    return canViewFinancialData(p);
                },
            },
            defaultTaxCalculationTypeField as any, // TODO
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION,
                id: 'description',
                type: FormItemType.RichText,
                validationRules: [
                    {
                        max: 5000,
                        message: (
                            <>
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__FIELD_LENGTH_MAXIMUM)} 5000{' '}
                                {localize(LocalizationEnum.ASPECT__FORMS__VALIDATION__INCLUDING_FORMATTING_TAGS)}
                            </>
                        ),
                    },
                ],
            },
        ],
    },
    {
        fields: [
            createHiddenField('counterpartyContactLinks'),
            createHiddenField('__tmpContactLinks'),
                {
                    label: 'Контакты по арендатору',
                    id: 'hasOwnContacts',
                    type: FormItemType.Switch,
                    labelCol: { span: 7 },
                    wrapperCol: { span: 12 },
                    defaultValue: true,
                    onChange: async (value: any, form: WrappedFormUtils) => {
                        if(value){
                            // Контакты по арендатору
                            form.setFieldsValue({__tmpContactLinks: form.getFieldValue('contactLinks')});
                            form.setFieldsValue({contactLinks: form.getFieldValue('counterpartyContactLinks')});
                        }else{
                            // Собственные котакты
                            let tmpContactLinks:ContactData[]|undefined = form.getFieldValue('__tmpContactLinks');
                            if(!tmpContactLinks || tmpContactLinks.length === 0 ) tmpContactLinks = [{
                                position: '',
                                isMainContact: true
                            }];
                            form.setFieldsValue({contactLinks: tmpContactLinks});
                        }
                    },
                },
            {
                id: 'contactLinks',
                type: FormItemType.Component,
                component: ContactsEditor,
                componentProps: (form: WrappedFormUtils):Partial<ContactsEditorProps>=>{
                    const hasOwnContacts = form.getFieldValue('hasOwnContacts');
                    let counterpartyContactLinks:ContactData[]|undefined = form.getFieldValue('counterpartyContactLinks');
                    return {
                        withMainContact: true,
                        disabled: !!hasOwnContacts,
                        required: !hasOwnContacts,
                        counterpartyContactsIds: counterpartyContactLinks?.filter(link=>link.contactId).map((link)=>link.contactId) as (number[]|undefined)
                    };
                },
                validationRules: [
                    {
                        validator: (rule, value: ContactLinkObjWrite[] | undefined, cb, values) => {
                            if(values && !values.hasOwnContacts){
                                if(!value || value.length === 0) cb('У проекта должен быть хотя бы один контакт');
                                else if(value && value.some((item) => item.isMainContact && !item.contactId)) cb('Основной контакт не выбран');
                                else if(value && value.every((item) => !item.isMainContact)) cb('Основной контакт не выбран');
                                else if(value && value.some((item) => !item.contactId && item.position)) cb('Контакт не выбран');
                                else if(value && value.every((item) => !item.contactId && !item.position)) cb('Контакт не выбран');
                                else cb();
                            }else cb();
                        },
                    },
                ],
            },
        ],
    },
    {
        fields: projectContractFields
    },
    {
        fields: [
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__COMMENT,
                id: 'comment',
                type: FormItemType.Text,
                maxLength: 2000,
            },
        ],
    },
];
