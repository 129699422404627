import React from 'react';
import { Card, Icon } from 'antd';
import { connect } from 'react-redux';
import { IRootState } from '../../../../../shared/reducers';
import { Spin } from '../../../../../components';
import { getOperationFormElementsListColumns } from './OperationFormElementsListColumns';
import { OperationElement, setElementIsNowEditing, setElementsListParams } from '../../reducers/operationForm.reducer';
import { OperationTypeCodeEnum, RentStateCodeEnum } from '../../../../../server/api';
import { IconFolder } from '../../../../../components/icons';
import { LocalizationEnum, localize } from '../../../../../localization';
import { getGridStorageData } from '../../../../../components/grid/utils';
import { Grid } from '../../../../../components/grid/Grid';
import { Avatar } from '../../../../../components/avatar/avatar';
import { canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { SelectedElementsActionButtonsBlock } from '../components/selectedElementsActionButtonsBlock/selectedElementsActionButtonsBlock';
import './OperationFormElementsList.less';
import { sortOperationElements } from '../../../../../shared/util/sortByFieldNameIgnoreCasing';
import { DEFAULT_ON_PAGE_SELECT_VALUES } from '../../../../../config/constants';
import { SetOperationShiftCountEnabledPopover } from '../../../dev/components/setOperationShiftCountEnabledPopover/setOperationShiftCountEnabledPopover';
import {ToggleScanSoundButton} from "../../../../../components/buttons/toggleScanSoundButton/toggleScanSoundButton";

interface IProps extends StateProps, DispatchProps {
    visible: boolean;
}

interface State {
    selectedIds: number[];
}

const GRID_NAME = 'operationForm-elements';

class Component extends React.Component<IProps, State> {
    private inited: boolean = false;
    private grid;

    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedIds: [],
        };
    }

    shouldComponentUpdate(nextProps: IProps, nextState) {
        if (!nextProps.visible) return false;
        return true;
    }

    componentDidMount = () => {
        console.log('OperationFormElementsList componentDidMount()');
        this.inited = true;

        let gridData = getGridStorageData(GRID_NAME);
        this.props.setElementsListParams({
            limit: gridData.params?.limit,
            sortOrder: gridData.params?.sortOrder,
            sortBy: gridData.params?.sortBy,
        });
    };

    clearSelection = () => {
        if (this.grid) this.grid.clearSelection();
    };

    onPageSizeChanged = (size) => {
        this.props.setElementsListParams({
            limit: size,
            page: 1,
        });
    };

    onSortedChange = (id: string, desc: boolean) => {
        this.props.setElementsListParams({
            sortBy: id,
            sortOrder: desc ? 'DESC' : 'ASC',
        });
    };

    onPageChanged = (page: number) => {
        this.props.setElementsListParams({
            page: page,
        });
    };

    render() {
        const { sortedData, newEntities, excludeColumns, canAddElements } = getOperationFormElementsListGridProperties({
            ...this.props,
            listParams: {
                limit: this.props.elements.limit,
                page: this.props.elements.page,
                sortBy: this.props.elements.sortBy,
                sortOrder: this.props.elements.sortOrder,
            },
        });
        let { elements } = this.props;

        return this.inited ? (
            <Spin spinning={false}>
                <Card bordered={false} className={'rr-operationForm-elementsList'}>
                    {newEntities.length > 0 ? (
                        <Grid
                            onSortedChange={this.onSortedChange}
                            onPageChanged={this.onPageChanged}
                            onPageSizeChanged={this.onPageSizeChanged}
                            filtered={newEntities ? newEntities.length : 0}
                            pageSize={elements.limit}
                            currentPage={elements.page}
                            columns={getOperationFormElementsListColumns()}
                            data={sortedData}
                            indexFieldName={'id'}
                            defaultSorted={elements.sortBy}
                            defaultSortDesc={elements.sortOrder === 'DESC'}
                            entityType={'element'}
                            gridName={GRID_NAME}
                            onPageSelect={DEFAULT_ON_PAGE_SELECT_VALUES}
                            alwaysRedraw={false}
                            exportBlock={<><ToggleScanSoundButton /><SetOperationShiftCountEnabledPopover /></>}
                            excludeColumns={[...(excludeColumns || []), 'stateCodeNew']}
                            sortingDisabled={this.state.selectedIds && this.state.selectedIds.length > 0}
                            onSelectionChanged={(value) => {
                                if (value && value.length > 0) {
                                    this.props.setElementIsNowEditing(true);
                                } else {
                                    this.props.setElementIsNowEditing(false);
                                }
                                this.setState({ selectedIds: value.map((item) => +item) });
                            }}
                            ref={(ref) => {
                                if (!this.grid) this.grid = ref;
                            }}
                            actionButtons={[
                                <SelectedElementsActionButtonsBlock
                                    selectedIds={this.state.selectedIds}
                                    onApply={() => {
                                        this.clearSelection();
                                    }}
                                    hideDateButtons={this.props.projectTemplate}
                                    hidePriceButtons={!this.props.canViewFinancialData}
                                />,
                            ]}
                            pinned={{ right: ['actions'] }}
                        />
                    ) : (
                        <div className={'rr-grid-notfound-block'}>
                            <Avatar className={'rr-avatar-big'}>
                                <Icon component={IconFolder} />
                            </Avatar>
                            <div className={'rr-grid-notfound-block-title'}>
                                {localize(LocalizationEnum.PAGE__NEW_OPERATION__TAB__COMPOSITION__GRID__EMPTY_COMPOSITION_TITLE)}
                            </div>
                            <div className={'rr-grid-notfound-block-message'}>
                                {canAddElements
                                    ? localize(
                                          LocalizationEnum.PAGE__NEW_OPERATION__TAB__COMPOSITION__GRID__EMPTY_COMPOSITION_SUBTITLE_WAREHOUSE_AVAILABLE
                                      )
                                    : localize(
                                          LocalizationEnum.PAGE__NEW_OPERATION__TAB__COMPOSITION__GRID__EMPTY_COMPOSITION_SUBTITLE_WAREHOUSE_UNAVAILABLE
                                      )}
                            </div>
                        </div>
                    )}
                </Card>
            </Spin>
        ) : null;
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        elements: storeState.operationForm.elements,
        entities: storeState.operationForm.elements.entities,
        operationTypeCode: storeState.operationForm.typeCode,
        operationCorrectionStateCode: storeState.operationForm.targetStateCode,
        projectTemplate: storeState.operationForm.projectTemplate,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        isSubrent: storeState.operationForm.isSubrent,
        elementIsNowEditing: storeState.operationForm.elementIsNowEditing,
        isSimpleOrder: storeState.operationForm.isSimpleOrder || false,
    };
};

const mapDispatchToProps = { setElementsListParams, setElementIsNowEditing };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const OperationFormElementsList = connect(mapStateToProps, mapDispatchToProps)(Component);

interface GetGridPropertiesFunctionArgs {
    elements: {
        entities: Array<OperationElement>;
    };
    operationTypeCode?: OperationTypeCodeEnum;
    operationCorrectionStateCode?: RentStateCodeEnum;
    canViewFinancialData: boolean;
    elementIsNowEditing: boolean;
    projectTemplate: boolean;
    isSubrent?: boolean;
    isSimpleOrder: boolean;

    listParams: {
        limit: number;
        page: number;
        sortBy?: string;
        sortOrder: 'ASC' | 'DESC' | undefined;
    };
}

export const getOperationFormElementsListGridProperties = (args: GetGridPropertiesFunctionArgs) => {
    let {
        elements,
        operationTypeCode,
        operationCorrectionStateCode,
        canViewFinancialData,
        elementIsNowEditing,
        projectTemplate,
        isSubrent,
        listParams,
        isSimpleOrder,
    } = args;
    let sortFieldName = listParams.sortBy || '';
    let startFrom = (listParams.page - 1) * listParams.limit;
    let sortAsc = listParams.sortOrder === 'ASC';

    // Тут из массива нужно сделать массив со вложенными элементами
    let newEntities: OperationElement[] = [];
    const entities = !elementIsNowEditing ? sortOperationElements(elements.entities, sortFieldName) : elements.entities;
    entities.forEach((entity0) => {
        let entity = { ...entity0 };
        if (!entity.kitId && !entity.parentId) newEntities.push(entity);
        else if (entity.kitId) {
            let subRows = entities.filter((entity1) => entity1.parentId === entity.id);
            subRows = subRows.map((item) => {
                let hasWarn = item.warnings && item.warnings.length;
                let hasError = item.problems && item.problems.length;
                return { ...item, __warn: hasWarn, __error: hasError };
            });
            entity['subRows'] = subRows;
            newEntities.push(entity);
        }
    });

    let sortedData: OperationElement[] = newEntities; //!elementIsNowEditing ? sortByFieldNameIgnoreCasing(newEntities, sortFieldName) : newEntities;

    if (sortFieldName === 'leftoverInstanceCount') {
        let newData = [
            ...sortedData.filter((el) => el.leftoverInstanceCount === 0),
            ...sortedData.filter((el) => !el.keepLeftover && el.leftoverInstanceCount !== 0),
            ...sortedData.filter((el) => el.keepLeftover && el.leftoverInstanceCount !== 0),
        ];
        sortedData = newData;
    }

    if (!sortAsc) sortedData.reverse();
    sortedData = sortedData.slice(startFrom, startFrom + listParams.limit);

    sortedData = sortedData.map((item) => {
        let hasWarn = item.warnings && item.warnings.length;
        let hasError = item.problems && item.problems.length;
        return { ...item, __warn: hasWarn, __error: hasError };
    });

    const canAddElements =
        operationTypeCode === OperationTypeCodeEnum.ORDER ||
        operationTypeCode === OperationTypeCodeEnum.DRAFT ||
        operationTypeCode === OperationTypeCodeEnum.RENT ||
        operationTypeCode === OperationTypeCodeEnum.BOOK ||
        (operationTypeCode === OperationTypeCodeEnum.CORRECT &&
            (operationCorrectionStateCode === RentStateCodeEnum.ORDERED ||
                operationCorrectionStateCode === RentStateCodeEnum.BOOKED ||
                operationCorrectionStateCode === RentStateCodeEnum.RENT));

    let excludeColumns = projectTemplate
        ? ['rentPeriodStartDate', 'rentPeriodEndDate', 'stateCode']
        : operationTypeCode === OperationTypeCodeEnum.DRAFT
        ? ['stateCode']
        : undefined;
    if (!canViewFinancialData) {
        if (!excludeColumns) excludeColumns = [];
        excludeColumns.push('effectivePrice', 'discount', 'finalPricePerShift', 'finalTotalPrice');
    }

    if (isSubrent) {
        if (!excludeColumns) excludeColumns = [];
        excludeColumns.push('subrentedInstanceCount');
    }

    return {
        sortedData,
        newEntities,
        excludeColumns,
        canAddElements,
    };
};
